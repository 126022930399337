"use client";

// Remove the CSS module import
// import styles from "./score-ticker.module.scss";
import ScoreTickerMatch from "./score-ticker-match";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { ChevronDown, Check } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/common/components/ui/dropdown-menu";

// Import from results-service and results types
import {
  useLeagues,
  useSeasons,
  useCompetitions,
  useRounds,
  useResultsSummary,
} from "../../results/services/results-service";
import type { ResultsSummary } from "../../results/types";
import { useState, useEffect, useCallback } from "react";

interface ScoreTickerClientProps {
  initialData: ResultsSummary;
}

export function ScoreTickerClient({ initialData }: ScoreTickerClientProps) {
  // State for selections
  const [selectedLeagueId, setSelectedLeagueId] = useState<string | undefined>(
    undefined
  ); // Remove default AFL ID
  const [selectedSeasonId, setSelectedSeasonId] = useState<number | undefined>(
    undefined
  );
  const [selectedCompetitionIds, setSelectedCompetitionIds] = useState<
    number[]
  >([]);
  const [currentRoundId, setCurrentRoundId] = useState<number>(
    initialData.round_id || 0
  );

  // Fetch data with React Query
  const { data: leaguesData } = useLeagues();
  const { data: seasonsData } = useSeasons(selectedLeagueId);
  const { data: competitionsData } = useCompetitions(
    selectedLeagueId,
    selectedSeasonId
  );

  // Prepare competition IDs for rounds query
  const allCompetitionIds = competitionsData?.competitions
    ? competitionsData.competitions.map((c) => c.id)
    : [];

  const roundsParams =
    selectedCompetitionIds.length > 0
      ? { competition_ids: selectedCompetitionIds }
      : allCompetitionIds.length > 0
      ? { competition_ids: allCompetitionIds }
      : null;

  const { data: roundsData } = useRounds(roundsParams);

  // Use placeholderData to maintain previous data during loading
  const { data: resultsSummary, isLoading: resultsSummaryLoading } =
    useResultsSummary(
      currentRoundId ||
        selectedLeagueId ||
        selectedSeasonId ||
        selectedCompetitionIds.length > 0
        ? {
            round_id: currentRoundId,
            league_ids: selectedLeagueId,
            season_id: selectedSeasonId,
            competition_ids:
              selectedCompetitionIds.length > 0
                ? selectedCompetitionIds
                : allCompetitionIds,
          }
        : null
    );

  // Auto-select competitions
  useEffect(() => {
    if (
      competitionsData?.competitions?.length &&
      selectedCompetitionIds.length === 0
    ) {
      // If only one competition, select it
      if (competitionsData.competitions.length === 1) {
        setSelectedCompetitionIds([competitionsData.competitions[0].id]);
      }
    }
  }, [competitionsData, selectedCompetitionIds]); // Add selectedCompetitionIds back to avoid warning

  // Auto-select default league
  useEffect(() => {
    if (leaguesData?.leagues?.length && !selectedLeagueId) {
      const defaultLeague =
        leaguesData.leagues.find((l) => l.default) || leaguesData.leagues[0];
      setSelectedLeagueId(defaultLeague.id);
    }
  }, [leaguesData, selectedLeagueId]);

  // Auto-select current season - only when seasonsData changes
  useEffect(() => {
    if (seasonsData?.seasons?.length && !selectedSeasonId) {
      const currentSeason =
        seasonsData.seasons.find((s) => s.current) || seasonsData.seasons[0];
      setSelectedSeasonId(currentSeason.id);
    }
  }, [seasonsData, selectedSeasonId]); // Remove selectedSeasonId dependency

  // Auto-select current round - only when roundsData changes
  useEffect(() => {
    if (roundsData?.rounds?.length && currentRoundId === 0) {
      const currentRound =
        roundsData.rounds.find((r) => r.current) || roundsData.rounds[0];
      setCurrentRoundId(currentRound.id);
    }
  }, [roundsData, currentRoundId]); // Remove currentRoundId dependency

  // Selected items for display
  const selectedLeague = leaguesData?.leagues?.find(
    (l) => l.id === selectedLeagueId
  );
  const selectedSeason = seasonsData?.seasons?.find(
    (s) => s.id === selectedSeasonId
  );
  const selectedRound = roundsData?.rounds?.find(
    (r) => r.id === currentRoundId
  );

  // Check if previous/next rounds are valid in the current selection
  const isPrevRoundValid =
    resultsSummary?.prev_round_id &&
    (!roundsData?.rounds || // Allow if rounds data is still loading
      roundsData.rounds.some(
        (round) => round.id === resultsSummary.prev_round_id
      ));

  const isNextRoundValid =
    resultsSummary?.next_round_id &&
    (!roundsData?.rounds || // Allow if rounds data is still loading
      roundsData.rounds.some(
        (round) => round.id === resultsSummary.next_round_id
      ));

  // Handler functions
  const handleLeagueChange = useCallback((leagueId: string) => {
    setSelectedLeagueId(leagueId);
    setSelectedSeasonId(undefined);
    setSelectedCompetitionIds([]);
    setCurrentRoundId(0);
  }, []);

  const handleSeasonChange = useCallback((seasonId: number) => {
    setSelectedSeasonId(seasonId);
    setSelectedCompetitionIds([]);
    setCurrentRoundId(0);
  }, []);

  const handleCompetitionChange = useCallback(
    (competitionId: number | null) => {
      // If null, it means "All Competitions" was selected
      if (competitionId === null) {
        setSelectedCompetitionIds([]);
      } else {
        setSelectedCompetitionIds([competitionId]);
      }
      setCurrentRoundId(0);
    },
    []
  );

  const handleRoundChange = useCallback((roundId: number) => {
    setCurrentRoundId(roundId);
  }, []);

  const handlePrevRound = useCallback(() => {
    if (resultsSummary?.prev_round_id && isPrevRoundValid) {
      setCurrentRoundId(resultsSummary.prev_round_id || 0);
    }
  }, [resultsSummary, isPrevRoundValid]);

  const handleNextRound = useCallback(() => {
    if (resultsSummary?.next_round_id && isNextRoundValid) {
      setCurrentRoundId(resultsSummary.next_round_id || 0);
    }
  }, [resultsSummary, isNextRoundValid]);

  // Function to get competition name without state updates (pure calculation)
  const getSelectedCompetitionName = useCallback(() => {
    // If there's only one available competition, use that name
    if (competitionsData?.competitions?.length === 1) {
      return competitionsData.competitions[0].short_name;
    }

    // If "All Competitions" is selected (empty array) but we have a current round
    if (
      selectedCompetitionIds.length === 0 &&
      resultsSummary?.matches &&
      resultsSummary.matches.length > 0
    ) {
      // Find the competition ID from the first match
      const match = resultsSummary.matches[0];
      if (match && match.competition_id) {
        // Find the competition that matches the current match's competition ID
        const currentRoundCompetition = competitionsData?.competitions?.find(
          (c) => c.id === match.competition_id
        );
        if (currentRoundCompetition) {
          return currentRoundCompetition.short_name;
        }
      }
    }

    // If explicitly selected competition
    if (selectedCompetitionIds.length > 0 && competitionsData?.competitions) {
      const competition = competitionsData.competitions.find(
        (c) => c.id === selectedCompetitionIds[0]
      );

      if (competition) {
        return competition.short_name;
      }
    }

    return "COMP";
  }, [competitionsData, resultsSummary, selectedCompetitionIds]);

  return (
    <div className="max-w-7xl m-auto bg-white">
      {/* Navigation bar - simplified */}
      <div className="flex h-8 border-b border-gray-200 bg-[#1c272c]">
        {/* Previous button - always visible but properly styled based on state */}
        <button
          onClick={handlePrevRound}
          disabled={!isPrevRoundValid || resultsSummaryLoading}
          className={`text-gray-300 ${
            isPrevRoundValid ? "hover:bg-slate-500" : ""
          } w-6 h-8 flex items-center justify-center ${
            isPrevRoundValid ? "bg-slate-600" : "bg-[#1c272c]"
          }`}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            className={`${
              !isPrevRoundValid
                ? "opacity-30"
                : resultsSummaryLoading
                ? "opacity-60"
                : "opacity-100"
            } transition-opacity duration-300`}
          />
        </button>

        {/* Central area with dropdowns */}
        <div className="flex-1 flex items-center text-white h-8">
          <div className="flex space-x-1 px-1 w-full text-[9px]">
            {/* League dropdown - simplified */}
            <DropdownMenu>
              <DropdownMenuTrigger className="bg-gray-700 text-white px-1 py-0.5 text-[9px] rounded h-5 flex items-center">
                <span
                  className={`uppercase font-medium ${
                    resultsSummaryLoading ? "opacity-60" : "opacity-100"
                  } transition-opacity duration-300`}
                >
                  {selectedLeague?.name || "LEAGUE"}
                </span>
                <ChevronDown className="h-3 w-3 ml-1" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="w-[120px]">
                {leaguesData?.leagues?.map((league) => (
                  <DropdownMenuItem
                    key={league.id}
                    onClick={() => handleLeagueChange(league.id)}
                    className="text-xs flex items-center"
                  >
                    {league.id === selectedLeagueId && (
                      <Check className="mr-1 h-3 w-3 text-red-600" />
                    )}
                    <span>{league.name}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>

            {/* Season dropdown - simplified */}
            <DropdownMenu>
              <DropdownMenuTrigger className="bg-gray-700 text-white px-1 py-0.5 text-[9px] rounded h-5 flex items-center">
                <span
                  className={`uppercase font-medium ${
                    resultsSummaryLoading ? "opacity-60" : "opacity-100"
                  } transition-opacity duration-300`}
                >
                  {selectedSeason?.name || "SEASON"}
                </span>
                <ChevronDown className="h-3 w-3 ml-1" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="w-[120px]">
                {seasonsData?.seasons?.map((season) => (
                  <DropdownMenuItem
                    key={season.id}
                    onClick={() => handleSeasonChange(season.id)}
                    className="text-xs flex items-center"
                  >
                    {season.id === selectedSeasonId && (
                      <Check className="mr-1 h-3 w-3 text-red-600" />
                    )}
                    <span>{season.name}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>

            {/* Competition dropdown - Only show if there's more than one competition */}
            {competitionsData?.competitions &&
              competitionsData.competitions.length > 1 && (
                <DropdownMenu>
                  <DropdownMenuTrigger className="bg-gray-700 text-white px-1 py-0.5 text-[9px] rounded h-5 flex items-center">
                    <span
                      className={`uppercase font-medium ${
                        resultsSummaryLoading ? "opacity-60" : "opacity-100"
                      } transition-opacity duration-300`}
                    >
                      {getSelectedCompetitionName()}
                    </span>
                    <ChevronDown className="h-3 w-3 ml-1" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start" className="w-[120px]">
                    <DropdownMenuItem
                      key="all-competitions"
                      onClick={() => handleCompetitionChange(null)}
                      className="text-xs flex items-center"
                    >
                      {selectedCompetitionIds.length === 0 && (
                        <Check className="mr-1 h-3 w-3 text-red-600" />
                      )}
                      <span>All Comps</span>
                    </DropdownMenuItem>
                    {competitionsData.competitions.map((competition) => (
                      <DropdownMenuItem
                        key={competition.id}
                        onClick={() => handleCompetitionChange(competition.id)}
                        className="text-xs flex items-center"
                      >
                        {selectedCompetitionIds.includes(competition.id) && (
                          <Check className="mr-1 h-3 w-3 text-red-600" />
                        )}
                        <span>{competition.short_name}</span>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}

            {/* Round dropdown - simplified */}
            <DropdownMenu>
              <DropdownMenuTrigger className="bg-gray-700 text-white px-1 py-0.5 text-[9px] rounded h-5 flex items-center">
                <span
                  className={`uppercase font-medium ${
                    resultsSummaryLoading ? "opacity-60" : "opacity-100"
                  } transition-opacity duration-300`}
                >
                  {selectedRound?.name || "ROUND"}
                </span>
                <ChevronDown className="h-3 w-3 ml-1" />
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="start"
                className="w-[120px] max-h-[300px] overflow-y-auto"
              >
                {roundsData?.rounds?.map((round) => (
                  <DropdownMenuItem
                    key={round.id}
                    onClick={() => handleRoundChange(round.id)}
                    className="text-xs flex items-center"
                  >
                    {round.id === currentRoundId && (
                      <Check className="mr-1 h-3 w-3 text-red-600" />
                    )}
                    <span>{round.name}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        {/* Next button - always visible but properly styled based on state */}
        <button
          onClick={handleNextRound}
          disabled={!isNextRoundValid || resultsSummaryLoading}
          className={`text-gray-300 ${
            isNextRoundValid ? "hover:bg-slate-500" : ""
          } w-6 h-8 flex items-center justify-center ${
            isNextRoundValid ? "bg-slate-600" : "bg-[#1c272c]"
          }`}
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            className={`${
              !isNextRoundValid
                ? "opacity-30"
                : resultsSummaryLoading
                ? "opacity-60"
                : "opacity-100"
            } transition-opacity duration-300`}
          />
        </button>
      </div>

      {/* Match list without any loading visual effects */}
      <div
        className={`flex flex-nowrap overflow-x-auto min-h-[80px] ${
          resultsSummaryLoading
            ? "opacity-60 transition-opacity duration-300"
            : ""
        }`}
      >
        {resultsSummary?.matches?.length ? (
          resultsSummary.matches.map((match) => (
            <ScoreTickerMatch match={match} key={match.id} />
          ))
        ) : (
          <div className="self-center justify-self-center m-auto py-2">
            No matches available
          </div>
        )}
      </div>
    </div>
  );
}
