import { SearchResult, SearchResponse } from "../types/search-types";
import { useApi } from "@/common/api";
import { useQuery } from "@tanstack/react-query";

/**
 * Creates a search service
 */
function createSearchService(api: ReturnType<typeof useApi>) {
  return {
    /**
     * Searches the Footyinfo database based on the provided query
     */
    search: async (
      query: string,
      limit: number = 10,
      offset: number = 0
    ): Promise<SearchResponse> => {
      if (!query || query.trim().length === 0) {
        return { results: [], pagination: { total: 0, offset, limit } };
      }

      try {
        // Build the query string
        const queryString = `q=${encodeURIComponent(
          query
        )}&limit=${limit}&offset=${offset}`;

        // Make the API request
        const response = await api.get<SearchResponse>(
          `/search?${queryString}`,
          { authenticated: false }
        );
        return response;
      } catch (error) {
        console.error("Error searching:", error);
        return { results: [], pagination: { total: 0, offset, limit } };
      }
    },
  };
}

/**
 * Hook to use the search service
 */
export function useSearchService() {
  const api = useApi();
  return createSearchService(api);
}

/**
 * Hook to search with React Query
 */
export function useSearch(
  query: string,
  limit: number = 10,
  offset: number = 0
) {
  const service = useSearchService();

  return useQuery({
    queryKey: ["search", query, limit, offset],
    queryFn: () => service.search(query, limit, offset),
    enabled: query.trim().length >= 2,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    placeholderData: (previousData) => previousData, // Keep previous data while loading new results
  });
}
