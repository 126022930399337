import { SignInButton } from "@/features/auth/components/sign-in-button";
import { UserDropdown } from "@/features/auth/components/user-dropdown";
import { getAuthState } from "@/features/auth/actions/auth-actions";

// Server component
export async function LoginStatus() {
  const { isAuthenticated } = await getAuthState();

  if (isAuthenticated) {
    return <UserDropdown />;
  }

  return <SignInButton />;
}
