import(/* webpackMode: "eager", webpackExports: ["MainLayout"] */ "/app/app/(main)/main-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAdSense"] */ "/app/common/components/analytics/google-adsense.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/app/common/components/analytics/google-analytics.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto-mono\"}],\"variableName\":\"robotoMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-ibm-plex-mono\"}],\"variableName\":\"ibmPlexMono\"}");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
