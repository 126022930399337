"use client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useSearch } from "../context/search-context";

interface SearchIconProps {
  onClick?: () => void;
  className?: string;
}

export function SearchIcon({ onClick, className }: SearchIconProps) {
  const { openSearch } = useSearch();

  const handleClick = () => {
    openSearch();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex items-center justify-center h-8 w-8 text-white hover:bg-white/10 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-white/50 ${
        className || ""
      }`}
      aria-label="Search players"
    >
      <FontAwesomeIcon icon={faMagnifyingGlass} className="h-4 w-4" />
    </button>
  );
}
