"use client";

import { useEffect } from "react";

interface GoogleAdSenseProps {
  adClientId: string;
}

export function GoogleAdSense({ adClientId }: GoogleAdSenseProps) {
  useEffect(() => {
    if (!adClientId || typeof window === "undefined") return;

    // Check if script is already loaded
    if (document.getElementById("google-adsense-script")) return;

    // Add the CSS to hide unfilled ads
    const styleElement = document.createElement("style");
    styleElement.id = "adsense-unfilled-style";
    styleElement.textContent = `
      /* Hide ad units that are unfilled */
      ins.adsbygoogle[data-ad-status="unfilled"] {
        display: none !important;
      }
    `;
    document.head.appendChild(styleElement);

    // Create and append the script directly
    const script = document.createElement("script");
    script.id = "google-adsense-script";
    script.async = true;
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClientId}`;
    script.crossOrigin = "anonymous";

    // Add to document head
    document.head.appendChild(script);
  }, [adClientId]);

  // This component doesn't render anything
  return null;
}
