"use client";

import React, { createContext, useContext, useState, ReactNode } from "react";
import { SearchResult } from "../types/search-types";
import { useSearch as useSearchQuery } from "../services/search-service";
import { useDebounce } from "@/hooks/use-debounce";

// Define the context type
interface SearchContextType {
  isSearchOpen: boolean;
  searchQuery: string;
  searchResults: SearchResult[];
  isLoading: boolean;
  hasMore: boolean;
  totalResults: number;
  openSearch: () => void;
  closeSearch: () => void;
  toggleSearch: () => void;
  setSearchQuery: (query: string) => void;
  loadMore: () => Promise<void>;
}

// Create context with defaults
const SearchContext = createContext<SearchContextType>({
  isSearchOpen: false,
  searchQuery: "",
  searchResults: [],
  isLoading: false,
  hasMore: false,
  totalResults: 0,
  openSearch: () => {},
  closeSearch: () => {},
  toggleSearch: () => {},
  setSearchQuery: () => {},
  loadMore: async () => {},
});

// Provider props
interface SearchProviderProps {
  children: ReactNode;
}

// Page size for search results
const SEARCH_PAGE_SIZE = 10;

// Provider component
export function SearchProvider({ children }: SearchProviderProps) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [offset, setOffset] = useState(0);

  // Debounce search query to avoid too many API calls
  // 300ms is the industry standard for a good balance between responsiveness and performance
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  // Use React Query to fetch search results
  const { data, isLoading } = useSearchQuery(
    debouncedSearchQuery,
    SEARCH_PAGE_SIZE,
    offset
  );

  // Extract data from query result
  const searchResults = data?.results || [];
  const totalResults = data?.pagination?.total || 0;

  // Calculate if there are more results to load
  const hasMore = searchResults.length < totalResults;

  // Toggle search panel
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      // Focus search input when opening
      setTimeout(() => {
        const searchInput = document.getElementById("search-input");
        if (searchInput) {
          searchInput.focus();
        }
      }, 100);
    }
  };

  // Open search panel
  const openSearch = () => {
    setIsSearchOpen(true);
    // Focus search input when opening
    setTimeout(() => {
      const searchInput = document.getElementById("search-input");
      if (searchInput) {
        searchInput.focus();
      }
    }, 100);
  };

  // Close search panel
  const closeSearch = () => {
    setIsSearchOpen(false);
    // Optional: Clear search query when closing
    // setSearchQuery('');
  };

  // Load more results
  const loadMore = async () => {
    if (!hasMore || isLoading || !searchQuery) return;
    setOffset(offset + SEARCH_PAGE_SIZE);
  };

  // Context value
  const value = {
    isSearchOpen,
    searchQuery,
    searchResults,
    isLoading,
    hasMore,
    totalResults,
    openSearch,
    closeSearch,
    toggleSearch,
    setSearchQuery,
    loadMore,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

// Custom hook to use the search context
export function useSearch() {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
}
