"use client";

interface AdProps {
  slot: string;
  format?: "auto" | "horizontal" | "vertical" | "rectangle";
  style?: React.CSSProperties;
  responsive?: boolean;
  className?: string;
}

export function Ad({
  slot,
  format = "auto",
  style = {},
  responsive = true,
  className = "",
}: AdProps) {
  // Validate essential parameters
  if (!slot || !process.env.NEXT_PUBLIC_ADSENSE_CLIENT_ID) {
    return null;
  }

  // Set up basic styles
  const adStyle: React.CSSProperties = {
    display: "block",
    ...style,
  };

  // Following Google's standard pattern for AdSense ads
  return (
    <div className={`ad-container ${className}`}>
      <ins
        className="adsbygoogle"
        style={adStyle}
        data-ad-client={process.env.NEXT_PUBLIC_ADSENSE_CLIENT_ID}
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={responsive ? "true" : "false"}
      />
      {/* Push ad when component mounts - standard AdSense approach */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (adsbygoogle = window.adsbygoogle || []).push({});
          `,
        }}
      />
    </div>
  );
}

// Add this for TypeScript global window augmentation
declare global {
  interface Window {
    adsbygoogle: any[] & {
      loaded?: boolean;
    };
  }
}
