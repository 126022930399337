"use client";

import { LoginStatusClient, AuthProvider, useAuth } from "@/features/auth";
import { handleSignIn } from "@/features/auth/actions/auth-actions";
import { ScoreTickerContextProvider } from "@/features/score-ticker";
import { ScoreTicker } from "@/features/score-ticker";
import { SearchIcon, SearchPanel, SearchProvider } from "@/features/search";
import { useSearch } from "@/features/search/context/search-context";
import Link from "next/link";
import Image from "next/image";
import React, { useState, useEffect } from "react";
import { Menu, X, Search, Home, LogOut } from "lucide-react";
import { usePathname, useSearchParams } from "next/navigation";
import { BannerAd } from "@/common/components/ads";

interface MainLayoutProps {
  children: React.ReactNode;
}

// Create a special component for the search item to properly use hooks
const SearchMenuItem = ({ onSearch }: { onSearch: () => void }) => {
  const { openSearch } = useSearch();

  const handleClick = () => {
    openSearch();
    onSearch();
  };

  return (
    <div
      className="flex items-center justify-between px-4 py-3 text-white hover:bg-white/10 transition-colors border-t border-white/10 cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex items-center gap-3">
        <Search size={18} />
        <span>Search</span>
      </div>
    </div>
  );
};

// Create a simplified account component for the mobile menu
const MobileAccountItem = ({ onClose }: { onClose: () => void }) => {
  const { isAuthenticated, screenName, email } = useAuth();
  const displayName = screenName || email?.split("@")[0] || "User";
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleLogout = () => {
    onClose();
    window.location.href = "/api/auth/logout";
  };

  const handleSignInClick = () => {
    onClose();
    // Create the full current path including search params
    let currentPath = pathname;
    const searchString = searchParams.toString();
    if (searchString) {
      currentPath += `?${searchString}`;
    }

    // Call handleSignIn with the current path as returnTo
    handleSignIn(currentPath);
  };

  return (
    <div className="px-4 py-4 text-white">
      <div className="flex items-center gap-3 mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <span className="font-medium">Account</span>
      </div>

      {isAuthenticated ? (
        <div className="pl-7 flex flex-col gap-2">
          <div className="font-medium">{displayName}</div>
          <button
            onClick={handleLogout}
            className="flex items-center gap-2 text-sm bg-white/20 hover:bg-white/30 py-1 px-3 rounded transition-colors mt-1"
          >
            <LogOut size={16} />
            Sign Out
          </button>
        </div>
      ) : (
        <div className="pl-7">
          <Link
            href="/api/auth/login"
            className="flex items-center gap-2 text-sm bg-white/20 hover:bg-white/30 py-1 px-3 rounded transition-colors w-fit"
            onClick={(e) => {
              e.preventDefault();
              handleSignInClick();
            }}
          >
            Sign In
          </Link>
        </div>
      )}
    </div>
  );
};

// Wrapper component that has access to the SearchProvider
const MainLayoutContent = ({ children }: MainLayoutProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [adContainerVisible, setAdContainerVisible] = useState(true);
  const adContainerRef = React.useRef<HTMLDivElement>(null);
  const { isSearchOpen } = useSearch();

  // Use MutationObserver to detect when AdSense changes ad status
  useEffect(() => {
    if (!adContainerRef.current) return;

    // Create a mutation observer to watch for changes to the data-ad-status attribute
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-ad-status" &&
          mutation.target instanceof HTMLElement
        ) {
          const status = mutation.target.getAttribute("data-ad-status");
          if (status === "unfilled") {
            setAdContainerVisible(false);
          }
        }
      });
    });

    // Find any AdSense elements that already exist
    const checkExistingAds = () => {
      const adElements =
        adContainerRef.current?.querySelectorAll("ins.adsbygoogle");
      if (!adElements || adElements.length === 0) return;

      // Check if any existing ads already have unfilled status
      adElements.forEach((ad) => {
        if (ad.getAttribute("data-ad-status") === "unfilled") {
          setAdContainerVisible(false);
        }

        // Observe each ad element for attribute changes
        observer.observe(ad, {
          attributes: true,
          attributeFilter: ["data-ad-status"],
        });
      });
    };

    // Initial check for already present ad elements
    checkExistingAds();

    // Set up observer for new ad elements that might be added dynamically
    const domObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          // New nodes added, check if any are AdSense elements
          mutation.addedNodes.forEach((node) => {
            if (
              node instanceof HTMLElement &&
              node.tagName === "INS" &&
              node.classList.contains("adsbygoogle")
            ) {
              // New AdSense element found, observe it
              observer.observe(node, {
                attributes: true,
                attributeFilter: ["data-ad-status"],
              });

              // Check its current status
              if (node.getAttribute("data-ad-status") === "unfilled") {
                setAdContainerVisible(false);
              }
            }
          });
        }
      });
    });

    // Observe the container for new AdSense elements
    domObserver.observe(adContainerRef.current, {
      childList: true,
      subtree: true,
    });

    // Fallback timeout as a last resort (much longer than before)
    const fallbackTimeout = setTimeout(() => {
      const adElement =
        adContainerRef.current?.querySelector("ins.adsbygoogle");
      if (!adElement || (adElement as HTMLElement).offsetHeight < 5) {
        setAdContainerVisible(false);
      }
    }, 8000);

    return () => {
      observer.disconnect();
      domObserver.disconnect();
      clearTimeout(fallbackTimeout);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Close mobile menu when search panel opens
  useEffect(() => {
    if (isSearchOpen) {
      closeMobileMenu();
    }
  }, [isSearchOpen]);

  // Add class to body when menu is open to prevent scrolling
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [mobileMenuOpen]);

  return (
    <div className="flex flex-col min-h-screen bg-slate-100">
      {/* Header section */}
      <header className="flex-none">
        {/* AdSense container with fixed dimensions that hides completely if ad fails to load */}
        {adContainerVisible && (
          <div className="w-full bg-slate-100 flex-none" ref={adContainerRef}>
            <div
              className="max-w-7xl mx-auto relative"
              style={{ height: "90px" }}
            >
              <BannerAd className="mx-auto my-2" />
            </div>
          </div>
        )}
        {/* Score ticker is positioned above the nav bar */}
        <div className="w-full bg-slate-100">
          <ScoreTicker />
        </div>
        {/* Navigation Bar */}
        <div className="bg-gradient-to-r text-white from-footyinfo-red to-orange-600 h-12 w-full shadow-md z-20">
          <div className="max-w-7xl m-auto flex flex-row items-center justify-between h-full">
            {/* Logo section - always visible with icon on left */}
            <div className="flex flex-row items-center h-full">
              <Link
                key={"homeLink"}
                href={"/"}
                className="pl-0 flex items-center h-full"
              >
                <Image
                  src={"/images/logo.png"}
                  alt={"Footyinfo"}
                  height={32}
                  width={32}
                />
              </Link>
              {/* Text is centered on mobile but not on desktop */}
              <Link href="/" className="flex items-center h-full">
                <div className="md:ml-2.5 absolute left-0 right-0 md:static text-center md:text-left text-xl pointer-events-none md:pointer-events-auto">
                  <span className="px-2 py-1 md:p-0 bg-transparent">
                    Footyinfo
                  </span>
                </div>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex md:flex-row md:items-center">
              <div className="flex h-12 items-center mr-6">
                <Link
                  href="/tipping"
                  className="text-white hover:text-gray-200 transition-colors"
                >
                  Tipping
                </Link>
              </div>
              <div className="flex items-center h-12 gap-4">
                <SearchIcon />
                <LoginStatusClient />
              </div>
            </div>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden h-full items-center z-10">
              <button
                onClick={toggleMobileMenu}
                className="text-white p-2 h-full flex items-center justify-center"
                aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
              >
                {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Slide-in Menu - Overlay + Sidebar */}
      <div
        className={`fixed inset-0 bg-black/50 z-30 md:hidden transition-opacity duration-300 ${
          mobileMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={closeMobileMenu}
      >
        {/* Prevent clicks on the sidebar from closing the menu */}
        <div
          className={`fixed top-0 right-0 h-full w-[280px] bg-gradient-to-br from-footyinfo-red to-red-700 text-white shadow-xl transition-transform duration-300 ease-in-out ${
            mobileMenuOpen ? "translate-x-0" : "translate-x-full"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Sidebar header */}
          <div className="flex items-center justify-between p-4 border-b border-white/20">
            <div className="text-lg font-semibold text-white">Menu</div>
            <button
              onClick={closeMobileMenu}
              className="text-white/80 hover:text-white"
              aria-label="Close menu"
            >
              <X size={20} />
            </button>
          </div>

          {/* Sidebar Navigation Items - Main Content */}
          <div
            className="flex flex-col py-2 overflow-y-auto"
            style={{ maxHeight: "calc(100% - 130px)" }}
          >
            {/* Home */}
            <Link
              href="/"
              className="flex items-center gap-3 px-4 py-3 text-white hover:bg-white/10 transition-colors"
              onClick={closeMobileMenu}
            >
              <Home size={18} />
              <span>Home</span>
            </Link>

            {/* Tipping */}
            <Link
              href="/tipping"
              className="flex items-center gap-3 px-4 py-3 text-white hover:bg-white/10 transition-colors border-b border-white/10"
              onClick={closeMobileMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M2 20h.01"></path>
                <path d="M7 20v-4"></path>
                <path d="M12 20v-8"></path>
                <path d="M17 20v-6"></path>
                <path d="M22 20V8"></path>
              </svg>
              <span>Tipping</span>
            </Link>

            {/* Search - Using the proper component with hooks */}
            <SearchMenuItem onSearch={closeMobileMenu} />
          </div>

          {/* Account - Pinned to bottom with simplified display */}
          <div className="absolute bottom-0 left-0 right-0 border-t border-white/20 bg-black/20">
            <MobileAccountItem onClose={closeMobileMenu} />
          </div>
        </div>
      </div>

      {/* Content wrapper - container that takes up all available space */}
      <div className="flex flex-col flex-grow">
        {/* Main content area with full height background */}
        <div className="flex-grow bg-slate-100">
          <main className="min-h-full w-full">{children}</main>
        </div>
      </div>

      {/* Footer - positioned at the bottom by flex layout */}
      <footer className="w-full py-4 border-t border-gray-200 bg-slate-100 mt-auto">
        <div className="max-w-7xl mx-auto px-4 flex flex-col md:flex-row justify-between items-center text-xs text-gray-500">
          <div>© Footyinfo 2025</div>
          <div className="mt-2 md:mt-0">
            <Link
              href="/privacy"
              className="hover:text-gray-700 transition-colors"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>

      {/* Render the search panel */}
      <SearchPanel />
    </div>
  );
};

export function MainLayout({ children }: MainLayoutProps) {
  return (
    <AuthProvider>
      <ScoreTickerContextProvider>
        <SearchProvider>
          <MainLayoutContent>{children}</MainLayoutContent>
        </SearchProvider>
      </ScoreTickerContextProvider>
    </AuthProvider>
  );
}
