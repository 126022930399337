"use client";

import { Ad } from "./Ad";

interface InContentAdProps {
  className?: string;
}

export function InContentAd({ className = "" }: InContentAdProps) {
  // Determine if placeholder should be shown - only in development mode unless explicitly enabled
  const isDev = process.env.NODE_ENV !== "production";
  const showDevAds = process.env.NEXT_PUBLIC_SHOW_DEV_ADS === "true";
  const showPlaceholder = isDev && !showDevAds;

  // Get the in-content ad slot ID if available
  const inContentSlotId = process.env.NEXT_PUBLIC_ADSENSE_INCONTENT_SLOT_ID;

  // In development, show placeholder unless NEXT_PUBLIC_SHOW_DEV_ADS is true
  if (showPlaceholder || !inContentSlotId) {
    return (
      <div
        className={`bg-gray-100 border border-dashed border-gray-300 p-4 text-center text-gray-500 ${className}`}
      >
        <p>Ad Placeholder (only visible in development)</p>
      </div>
    );
  }

  return (
    <Ad
      slot={inContentSlotId}
      format="rectangle"
      className={className}
      style={{ minHeight: "250px", width: "100%" }}
      responsive={true}
    />
  );
}
