"use client";

import { createContext } from "react";

// Simple empty context that's not being used but kept for compatibility
export const ScoreTickerContext = createContext<{
  roundId: number | null;
  setRoundId: (roundId: number) => void;
  userHasSelectedRound: boolean;
  setUserHasSelectedRound: (hasSelected: boolean) => void;
}>({
  roundId: null,
  setRoundId: () => {},
  userHasSelectedRound: false,
  setUserHasSelectedRound: () => {},
});

// Simple provider that doesn't actually do anything but is kept for compatibility
export function ScoreTickerContextProvider({ children }) {
  return (
    <ScoreTickerContext.Provider
      value={{
        roundId: null,
        setRoundId: () => {},
        userHasSelectedRound: false,
        setUserHasSelectedRound: () => {},
      }}
    >
      {children}
    </ScoreTickerContext.Provider>
  );
}
