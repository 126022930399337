import Link from "next/link";
import Image from "next/image";
import { format } from "date-fns";
import { TeamLogo } from "@/common/components/image/team-logo";
import type { ResultsSummaryMatch } from "../../results/types";

interface ScoreTickerMatchProps {
  match: ResultsSummaryMatch;
}

export default function ScoreTickerMatch({ match }: ScoreTickerMatchProps) {
  let matchStatus = "";
  let isLive = false;
  let isBreak = false;

  if (match.status == "C") {
    matchStatus = "Full time";
  } else if (match.status == "L") {
    switch (match.middle) {
      case "QT":
        matchStatus = "Quarter time";
        isBreak = true;
        break;
      case "HT":
        matchStatus = "Half time";
        isBreak = true;
        break;
      case "3QT":
        matchStatus = "3 Quarter time";
        isBreak = true;
        break;
      case "FT":
        matchStatus = "Full time";
        isBreak = true;
        break;
      default:
        matchStatus = `Live ${match.middle} ${Math.floor(
          (match.quarter_duration || 0) / 60
        )}'`;
        isLive = true;
        break;
    }
  } else {
    const matchDate = new Date((match.utc_time || 0) * 1000);
    matchStatus = format(matchDate, "EEE d MMM h:mma");
  }

  // For the animation classes, we'll add them to globals.css later
  const statusClass = isLive ? "status-live" : isBreak ? "status-break" : "";

  return (
    <Link
      href={`/match/${match.slug || match.id}`}
      key={`matchLink${match.id}`}
      prefetch={false}
      className="text-[11px] bg-white flex-col border-r border-l-gray-400 h-full min-w-[123px] max-w-[160px] flex-grow basis-[140px] pl-2 pr-2 pt-1 pb-1 hover:bg-slate-200 hover:cursor-pointer block"
      onClick={(e) => {
        // Stop propagation to prevent parent event handlers from interfering
        e.stopPropagation();
      }}
    >
      <div
        className={`uppercase text-[10px] font-bold mb-0.5 tracking-tight ${statusClass}`}
      >
        {matchStatus}
      </div>
      <div className="text-[10px] text-gray-400 truncate">
        {match.ground_name_full || match.ground_name}
      </div>
      <div className="mt-1">
        <div
          className={`flex flex-row items-center justify-between text-gray-700 ${
            match.middle == "df" ? "font-bold" : ""
          }`}
        >
          <div className="w-4 h-4">
            <TeamLogo teamAbbrev={match.home_team} width={16} height={16} />
          </div>
          <div className="flex-[0.55] ml-1.5">{match.home_team}</div>
          <div className="flex flex-1 flex-row">
            <div className="self-center flex-[0.5] text-right">
              {match.status == "U"
                ? null
                : `${match.home_goals || 0}.${match.home_behinds || 0}`}
            </div>
            <div className="self-center flex-[0.5] text-right">
              {match.home_score}
            </div>
          </div>
        </div>
        <div
          className={`flex flex-row items-center justify-between text-gray-700 ${
            match.middle == "lt" ? "font-bold" : ""
          }`}
        >
          <div className="w-4 h-4">
            <TeamLogo teamAbbrev={match.away_team} width={16} height={16} />
          </div>
          <div className="flex-[0.55] ml-1.5">{match.away_team}</div>
          <div className="flex flex-1 flex-row">
            <div className="self-center flex-[0.5] text-right">
              {match.status == "U"
                ? null
                : `${match.away_goals || 0}.${match.away_behinds || 0}`}
            </div>
            <div className="self-center flex-[0.5] text-right">
              {match.away_score}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
