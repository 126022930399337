"use client";

import { ScoreTickerClient } from "./score-ticker";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import { useResultsSummary } from "@/features/results/services/results-service";

function LoadingState() {
  return (
    <div className="flex items-center justify-center h-32">
      <LoadingSpinner />
    </div>
  );
}

/**
 * Client-side score ticker component that fetches data on the client
 * This allows parent pages to be statically generated at build time
 */
export function ScoreTicker() {
  // Use React Query to fetch data on the client side
  const {
    data: resultsSummary,
    isLoading,
    error,
  } = useResultsSummary(
    // Pass empty round_id object to get current round
    { round_id: undefined },
    {
      // Set good defaults for client-side polling of score data
      refetchInterval: 30 * 1000, // Refetch every 30 seconds
      refetchOnWindowFocus: true,
    }
  );

  if (isLoading) {
    return <LoadingState />;
  }

  if (
    error ||
    !resultsSummary ||
    !resultsSummary.matches ||
    resultsSummary.matches.length === 0
  ) {
    return (
      <div className="flex items-center justify-center h-32">
        <div className="text-gray-500">Unable to load scores</div>
      </div>
    );
  }

  return <ScoreTickerClient initialData={resultsSummary} />;
}
