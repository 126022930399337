"use client";

import { useEffect, useRef } from "react";
import { useSearch } from "../context/search-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faXmark,
  faChevronRight,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";

export function SearchPanel() {
  const {
    isSearchOpen,
    closeSearch,
    searchQuery,
    setSearchQuery,
    searchResults,
    isLoading,
    totalResults,
  } = useSearch();

  const panelRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Close the panel when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        closeSearch();
      }
    }

    if (isSearchOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isSearchOpen, closeSearch]);

  // Focus input when panel opens
  useEffect(() => {
    if (isSearchOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchOpen]);

  // Handle escape key to close panel
  useEffect(() => {
    function handleEscape(event: KeyboardEvent) {
      if (event.key === "Escape") {
        closeSearch();
      }
    }

    if (isSearchOpen) {
      document.addEventListener("keydown", handleEscape);
      return () => {
        document.removeEventListener("keydown", handleEscape);
      };
    }
  }, [isSearchOpen, closeSearch]);

  if (!isSearchOpen) return null;

  // Determine if we should show the initial instructions
  const showInstructions = searchQuery.length < 2;

  // Determine if we should show no results message
  // Only when there are no results AND we're not loading AND we have a valid query
  const showNoResults =
    !isLoading && searchResults.length === 0 && searchQuery.length >= 2;

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-start justify-center pt-16 px-4">
      <div
        ref={panelRef}
        className="bg-white rounded-lg shadow-lg w-full max-w-lg overflow-hidden"
      >
        {/* Search header with input */}
        <div className="relative border-b">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="text-gray-400 h-4 w-4"
            />
          </div>
          <input
            id="search-input"
            ref={inputRef}
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Footyinfo..."
            autoComplete="off"
            className="w-full p-4 pl-10 pr-10 text-sm text-gray-900 focus:outline-none"
          />
          {searchQuery && (
            <button
              onClick={() => setSearchQuery("")}
              className="absolute inset-y-0 right-12 flex items-center pr-1"
              title="Clear search"
            >
              <FontAwesomeIcon
                icon={faDeleteLeft}
                className="h-4 w-4 text-gray-400 hover:text-gray-600"
              />
            </button>
          )}
          <button
            onClick={closeSearch}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            title="Close search"
          >
            <FontAwesomeIcon
              icon={faXmark}
              className="h-5 w-5 text-gray-400 hover:text-gray-600"
            />
          </button>
        </div>

        {/* Search results */}
        <div className="max-h-80 overflow-y-auto">
          {showInstructions ? (
            <div className="p-4 text-sm text-gray-500 text-center">
              Type at least 2 characters to search
            </div>
          ) : searchQuery.length >= 2 &&
            isLoading &&
            searchResults.length === 0 ? (
            <div className="p-4 flex justify-center items-center text-gray-500">
              <LoadingSpinner size="sm" />
              <span className="ml-2">Searching...</span>
            </div>
          ) : showNoResults ? (
            <div className="p-4 text-sm text-gray-500 text-center">
              No results found for &ldquo;{searchQuery}&rdquo;
            </div>
          ) : (
            <>
              <div className="text-xs text-gray-500 p-2 pl-4 flex items-center">
                {isLoading && <LoadingSpinner size="sm" className="mr-2" />}
                {totalResults} {totalResults === 1 ? "result" : "results"} found
              </div>
              <ul className="divide-y divide-gray-100">
                {searchResults.map((result) => (
                  <li key={result.entityId}>
                    <Link
                      href={`/player/${result.slug || result.entityId}`}
                      className="flex items-center justify-between p-3 hover:bg-gray-50 transition-colors"
                      onClick={closeSearch}
                    >
                      <div>
                        <div className="font-medium text-sm">
                          {result.title}
                        </div>
                        {result.subtitle && (
                          <div className="text-xs text-gray-500 mt-0.5">
                            {result.subtitle}
                          </div>
                        )}
                      </div>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="h-3 w-3 text-gray-400"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
