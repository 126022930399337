"use client";

import { Ad } from "./Ad";

interface BannerAdProps {
  className?: string;
}

export function BannerAd({ className = "" }: BannerAdProps) {
  // Determine if placeholder should be shown - only in development mode unless explicitly enabled
  const isDev = process.env.NODE_ENV !== "production";
  const showDevAds = process.env.NEXT_PUBLIC_SHOW_DEV_ADS === "true";
  const showPlaceholder = isDev && !showDevAds;

  // Get the banner-specific slot ID if available, otherwise fall back to general slot ID
  const bannerSlotId =
    process.env.NEXT_PUBLIC_ADSENSE_BANNER_SLOT_ID ||
    process.env.NEXT_PUBLIC_ADSENSE_SLOT_ID;

  // In development, show placeholder unless NEXT_PUBLIC_SHOW_DEV_ADS is true
  // Also show placeholder if no slot ID is available
  if (showPlaceholder || !bannerSlotId) {
    return (
      <div
        className={`w-full bg-gray-100 border border-dashed border-gray-300 p-2 text-center text-gray-500 ${className}`}
        style={{ height: "90px" }}
      >
        <p className="leading-loose">Banner Ad Placeholder (728×90)</p>
      </div>
    );
  }

  return (
    <Ad
      slot={bannerSlotId}
      format="horizontal"
      className={`w-full ${className}`}
      style={{
        minHeight: "90px",
        width: "100%",
        maxWidth: "728px",
        margin: "0 auto",
      }}
      responsive={true}
    />
  );
}
